import StepCard from '../StepCard/StepCard';
import { IOnSelection } from '../../../types/IOnSelection';
import Form from 'antd/es/form';
import { Button, Input, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { currentEngagement, insererStore, screenStore } from '../../../stores';
import { PartnersFetcher } from '../../../fetchers/role-fetchers/PartnersFetcher';
import { observer } from 'mobx-react';
import styles from './Partner.module.less';
import { Engagement } from '../../../types/Engagement';
import { useParams } from 'react-router-dom';

interface IInfosProps extends IOnSelection {
}

export enum SurPlace {
    Locataire = 'Locataire',
    Agent = 'Agent',
    Proprietaire = 'Proprietaire',
    Autre = 'Autre'
}

enum Partenaire {
    Agent = 'Agent',
    Notaire = 'Notaire'
}

const Partner = ({ onSelection }: IInfosProps) => {
    const [formAgentImmo] = Form.useForm();
    const [formLocataire] = Form.useForm();
    const [formAutreSurPlace] = Form.useForm();
    const [autreSurPlace, setAutreSurPlace] = useState<boolean>(false);
    const [EnvoiRapportPartner, setEnvoiRapportPartner] = useState<boolean>(false);
    const [locataireSurPlace, setlocataireSurPlace] = useState<boolean>(false);
    const [engagement, setEngagement] = useState<Engagement>();
    const [agentExist, setAgentExist] = useState<boolean>(false);
    const [idAgent, setIdAgent] = useState<number>()
    const { id_agent } = useParams();

    useEffect(() => {
        const infos = currentEngagement.getInfos();
        formLocataire.setFieldsValue(infos);
        formAutreSurPlace.setFieldsValue(infos);
        setEngagement(currentEngagement.getCurrentEngagement());
        insererStore.setNext(true);
        if(!id_agent) getPartner(infos?.proprietaire_telephone || "");          
        if (infos?.autre_sur_place) setAutreSurPlace(true);
        if (infos?.sur_place == SurPlace.Locataire) setlocataireSurPlace(true);
        if ((infos?.envoi_rapport_agent || infos?.envoi_rapport_notaire)) setEnvoiRapportPartner(true);
        
    }, []);

    const getPartner = async (tel: string) => {
        const { data } = await PartnersFetcher.getPartner({ telephone: tel, type_partenaire: 1 });
        if (data.length > 0) {
            const { nom, prenom, mail, id, cp, ste } = data[0]

            if (id) {
                setEnvoiRapportPartner(true);
                currentEngagement.setInfos({ envoi_rapport_agent: true })
                setAgentExist(true)
                formAgentImmo.setFieldValue('nom', nom + ' ' + prenom)
                formAgentImmo.setFieldValue('mail', mail)
                formAgentImmo.setFieldValue('cp', cp)
                formAgentImmo.setFieldValue('tel', tel)
                formAgentImmo.setFieldValue('agence', ste)
                setIdAgent(id)
            }
        }


    }

    const saveForm = (values: any) => {
        currentEngagement.setInfos({ [values[0].name[0]]: values[0].value });
    }

    const finishForm = async () => {
        const nom = formAgentImmo.getFieldValue('nom')
        const tel = formAgentImmo.getFieldValue('tel')
        const mail = formAgentImmo.getFieldValue('mail')
        const agence = formAgentImmo.getFieldValue('agence')
        const cp = formAgentImmo.getFieldValue('cp')
        const type_partenaire = currentEngagement.getInfos()?.envoi_rapport_agent ? 1 : 2;
        if (!agentExist && nom != undefined && mail != undefined) {
            const agent = {
                nom,
                mail,
                tel,
                agence,
                cp,
                type_partenaire
            }

            const { data } = await PartnersFetcher.addPartner(agent);
            console.log({ data })
            currentEngagement.setInfos({ id_agent: data.id, nom_agent: nom })
        }

        if (idAgent && nom != undefined && mail != undefined) {
            currentEngagement.setInfos({ id_agent: idAgent, nom_agent: nom })
        }
        onSelection()
    }

    const completeAgent = async (e: any) => {
        const value = e.target.value;
        if (value.length > 9) {
            await getPartner(value)
        }
    }

    const onOptionChangedFacturer = (e: any) => {
        const value: boolean = e.target.value;
        currentEngagement.setInfos({ agent_facturer: value })
    }

    const onOptionChangedSurPlace = (e: any) => {
        const value: string = e.target.value;
        currentEngagement.setInfos({ sur_place: value })
        if (value === SurPlace.Autre) {
            setAutreSurPlace(true)
            currentEngagement.setInfos({ autre_sur_place: true, sur_place: value })
        } else {
            setAutreSurPlace(false)
            currentEngagement.setInfos({ autre_sur_place: false, sur_place: value })
        }
        if (value === SurPlace.Locataire) {
            setlocataireSurPlace(true)
        } else {
            setlocataireSurPlace(false)
        }
    }

    const onOptionChangedRapports = (e: any) => {
        const value: string = e.target.value;
        if (value === Partenaire.Agent) {
            setEnvoiRapportPartner(true)
            currentEngagement.setInfos({ envoi_rapport_agent: true, envoi_rapport_notaire: false })
        }
        if (value === Partenaire.Notaire) {
            setEnvoiRapportPartner(true)
            currentEngagement.setInfos({ envoi_rapport_notaire: true, envoi_rapport_agent: false })
        }
    }

    return (
        <StepCard title="">
            {!id_agent && <><StepCard title='Envoi des rapports'>
                <div>Vous pouvez choisir d'envoyer directement les rapports de diagnostics à votre agent immobilier ou à votre notaire</div>
                <br></br>
                <Radio.Group buttonStyle='solid' onChange={onOptionChangedRapports}
                    size={screenStore.getSize()} value={currentEngagement.getInfos()?.envoi_rapport_agent ? Partenaire.Agent : currentEngagement.getInfos()?.envoi_rapport_notaire ? Partenaire.Notaire : undefined}>
                    <Radio.Button value={Partenaire.Agent}>Envoyer à mon agent immobilier</Radio.Button>
                    <Radio.Button value={Partenaire.Notaire}>Envoyer à mon notaire</Radio.Button>
                </Radio.Group>
                <br></br><br></br>
                {EnvoiRapportPartner && <Form
                    form={formAgentImmo}
                    name="basic"
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 12 }}
                    style={{ maxWidth: 700, textAlignLast: "start" }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    onFieldsChange={saveForm}
                >

                    <Form.Item
                        label="Tel"
                        name="tel"
                    >
                        <Input size='large' onChange={completeAgent} />
                    </Form.Item>

                    <Form.Item
                        label="Nom"
                        name="nom"
                    >
                        <Input size='large' style={{ textTransform: 'capitalize' }} />
                    </Form.Item>

                    <Form.Item
                        label="CP:"
                        name="cp"
                    >
                        <Input size='large' style={{ textTransform: 'capitalize' }} />
                    </Form.Item>

                    <Form.Item
                        label="Agence"
                        name="agence"
                    >
                        <Input size='large' style={{ textTransform: 'capitalize' }} />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="mail"
                    >
                        <Input size='large' />
                    </Form.Item>
                </Form>}
            </StepCard><br></br></>}
            <StepCard title='Qui sera sur place ? '>
                <Radio.Group buttonStyle='solid' onChange={onOptionChangedSurPlace}
                    size={screenStore.getSize()} value={currentEngagement.getInfos()?.sur_place}>
                    <Radio.Button value={SurPlace.Proprietaire}>Propriétaire</Radio.Button>
                    <Radio.Button value={SurPlace.Locataire}>Locataire</Radio.Button>
                    <Radio.Button value={SurPlace.Agent}>Mon agent</Radio.Button>
                    <Radio.Button value={SurPlace.Autre}>Autre</Radio.Button>
                </Radio.Group>
                <br></br><br></br>
                {locataireSurPlace &&
                    <Form
                        form={formLocataire}
                        name="basic"
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 12 }}
                        style={{ maxWidth: 700, textAlignLast: "start" }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onFieldsChange={saveForm}
                    >
                        <Form.Item
                            label="Nom"
                            name="locataire"
                        >
                            <Input size='large' style={{ textTransform: 'capitalize' }} />
                        </Form.Item>

                        <Form.Item
                            label="Tel"
                            name="telLocataire"
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Form>}
                {autreSurPlace &&
                    <Form
                        form={formAutreSurPlace}
                        name="basic"
                        labelCol={{ span: 2 }}
                        wrapperCol={{ span: 12 }}
                        style={{ maxWidth: 700, textAlignLast: "start" }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onFieldsChange={saveForm}
                    >

                        <Form.Item
                            label="Nom"
                            name="nom_sur_place"
                        >
                            <Input size='large' style={{ textTransform: 'capitalize' }} />
                        </Form.Item>

                        <Form.Item
                            label="Tel"
                            name="tel_sur_place"
                        >
                            <Input size='large' style={{ textTransform: 'capitalize' }} />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            name="mail_sur_place"
                        >
                            <Input size='large' />
                        </Form.Item>
                    </Form>}
            </StepCard>
            <br></br>
            <StepCard title='Qui Facturer ? '>
                <Radio.Group buttonStyle='solid' onChange={onOptionChangedFacturer}
                    size={screenStore.getSize()} value={currentEngagement.getInfos()?.agent_facturer}>
                    <Radio.Button value={false}>Propriétaire</Radio.Button>
                    <Radio.Button value={true}>L'agence</Radio.Button>
                </Radio.Group>
            </StepCard>

            <br></br>
            <div className={styles.formButtons}>
                <Button type="primary" className='button' onClick={finishForm}>
                    Continuer
                </Button>
            </div>
        </StepCard>)
}

export default observer(Partner);