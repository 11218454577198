import StepCard from '../StepCard/StepCard';
import { IOnSelection } from '../../../types/IOnSelection';
import Form from 'antd/es/form';
import { AutoComplete, Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { currentEngagement, insererStore } from '../../../stores';
import styles from './Infos.module.less';
import { SearchsFetcher, AddressesResponses } from '../../../fetchers/role-fetchers/SearchsFetcher';
import TextArea from 'antd/es/input/TextArea';

interface IInfosProps extends IOnSelection {
}

const Infos = ({ onSelection }: IInfosProps) => {
    const [form] = Form.useForm();
    const [options, setOptions] = useState<{ value: string, code: number }[]>([]);

    const onSelect = (data: any) => {
        console.log('onSelect', data);
    };

    const onChange = (data: any) => {
        console.log(data);
    };

    const getClient = async (telephone: string) => {
        const { data } = await SearchsFetcher.getClient(telephone);
        if (data.length > 0) {
            const { proprietaire, mail, adresse } = data[0]

            if (proprietaire) {
                form.setFieldValue('proprietaire_nom', proprietaire)
                form.setFieldValue('proprietaire_email', mail)
                form.setFieldValue('bien_adresse', adresse)
            }
        }

    }

    const getOptions = async (text: string) => {
        const response: { data: AddressesResponses; status: number } = await SearchsFetcher.searchAddress(text);

        setOptions([]);
        if (response.status === 200) {
            const options: { value: string, code: number }[] = [];
            response.data.adresses?.forEach((adresse) => {
                options.push({ value: adresse.adresse, code: adresse.code_postal })
            });

            setOptions(options);
        }
    }

    useEffect(() => {
        const infos = currentEngagement.getInfos();
        getClient(infos?.proprietaire_telephone || "");
        if (infos?.proprietaire_nom && infos?.bien_adresse) insererStore.setNext(true);
        form.setFieldsValue(infos);
    }, []);

    const saveForm = (values: any) => {
        currentEngagement.setInfos(values);
        onSelection();
    }

    return (
        <StepCard title='Informations'>
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 700 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={saveForm}
                className={styles.form}

            >
                <Form.Item
                    label="Nom et prénom"
                    name="proprietaire_nom"
                    rules={[{ required: true, message: 'Veuillez entrez votre nom et prénom' }]}
                >
                    <Input size='large' style={{ textTransform: 'capitalize' }} />
                </Form.Item>

                <Form.Item
                    label="Addresse du bien"
                    name="bien_adresse"
                    rules={[{ required: true, message: 'Veuillez entrez une addresse' }]}
                >
                    <AutoComplete
                        size='large'
                        options={options}
                        onSelect={onSelect}
                        onSearch={(text: string) => getOptions(text)}
                        onChange={onChange}
                        style={{ textTransform: 'capitalize' }} />
                </Form.Item>

                <Form.Item
                    label="Addresse email"
                    name="proprietaire_email"
                    rules={[{ type: 'email', message: 'Veuillez entrez votre addresse email' }]}
                >
                    <Input size='large' />
                </Form.Item>

                <Form.Item
                    label="Notes"
                    name="note"
                >
                    <TextArea size='large' />
                </Form.Item>


                <Button type="primary" className="button" htmlType="submit">
                    Valider
                </Button>

            </Form>
        </StepCard>
    )
}

export default Infos;